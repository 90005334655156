import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

// Configurations
import Config from '@/config'

// Types
import type { UserStore } from '@/types/helpers/store/user'

export const useUserStore = create(
	persist<UserStore>(
		set => ({
			isLogin: false,
			user_name: '',
			username: '',
			setIsLogin: (isLogin: boolean) => set({ isLogin }),
			setUser_Name: (user_name: string) => set({ user_name }),
			setUsername: (username: string) => set({ username }),
			reset: () => set({ isLogin: false, user_name: '', username: '' })
		}),
		{
			name: `${Config.shortName}-user`,
			// storage: createJSONStorage(() => localStorage),
			// Check if window is defined before accessing localStorage
			...(typeof window !== 'undefined' && { storage: createJSONStorage(() => localStorage) })
		}
	)
)
