'use client'

import { useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { AppBar, Toolbar, Grid2 as Grid, Container, Avatar, Button } from '@mui/material'

// Types
import type { AppBarProps } from '@/types/components/organisms/app-bar'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'
import { useUserStore } from '@/helpers/store/user'

// Components
const HideOnScroll = dynamic(() => import('@/components/theme/hide-on-scroll'))
const MenuMolecule = dynamic(() => import('@/components/molecules/menu/desktop'))
const LogoAtom = dynamic(() => import('@/components/atoms/logo'))
const GeneralButtonAtom = dynamic(() => import('@/components/atoms/buttons/general'))
const IconButtonAtom = dynamic(() => import('@/components/atoms/buttons/icon'))
const NotificationIconAtom = dynamic(() => import('@/components/atoms/icons/notification'))
const ShoppingTrollyIconAtom = dynamic(() => import('@/components/atoms/icons/shopping-trolly'))
const UserDesktopMenuOrganism = dynamic(() => import('@/components/organisms/menu/user-desktop'))

const AppBarOrganism = (props: AppBarProps) => {
	// Props
	const { menus, avatarSRC } = props

	// Variables
	const { isLogin, username } = useUserStore()
	const { t } = useTranslation()
	const pathname = usePathname()
	const refUserMenuButton = useRef<HTMLButtonElement>(null)
	const [openUserMenu, setOpenUserMenu] = useState<boolean>(false)

	return (
		<HideOnScroll onlyDesktop={pathname !== '/'}>
			<AppBar
				elevation={0}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: pathname === '/' ? 'relative' : 'fixed',
					bgcolor: 'common.white',
					'& .MuiToolbar-root': { p: 0, pt: 4 }
				}}
			>
				<Toolbar>
					<Container maxWidth="xl">
						<Grid container width={1} direction="row" alignItems="center" justifyContent="space-between">
							<Grid container alignItems="center">
								<Grid>
									<LogoAtom />
								</Grid>

								<Grid>
									<MenuMolecule items={menus} />
								</Grid>
							</Grid>

							<Grid>
								{!isLogin && (
									<Grid container spacing={1.5} direction="row" justifyContent="flex-start" alignItems="center">
										<Grid>
											<Link href="/signin">
												<GeneralButtonAtom title={t('form:button.signIn')} />
											</Link>
										</Grid>

										<Grid>
											<Link href="/register">
												<GeneralButtonAtom title={t('form:button.register')} />
											</Link>
										</Grid>
									</Grid>
								)}

								{isLogin && (
									<Grid container spacing={0.5} direction="row" justifyContent="flex-start" alignItems="center">
										<Grid>
											<Link href="/notifications">
												<IconButtonAtom icon={<NotificationIconAtom />} />
											</Link>
										</Grid>

										<Grid>
											<Link href="/shopping-cart">
												<IconButtonAtom icon={<ShoppingTrollyIconAtom />} />
											</Link>
										</Grid>

										<Grid mx={2} sx={{ '& .MuiButton-root': { px: 2 } }}>
											<Link href="/my-courses">
												<GeneralButtonAtom title={t('form:button.myCourses')} />
											</Link>
										</Grid>

										<Grid>
											<Button ref={refUserMenuButton} onClick={() => setOpenUserMenu(!openUserMenu)} sx={{ p: 0, minWidth: '40px', borderRadius: '50%' }}>
												<Avatar alt={username} src={avatarSRC} sx={{ bgcolor: 'primary.light' }}>
													{username ? username.charAt(0).toUpperCase() : null}
												</Avatar>
											</Button>

											<UserDesktopMenuOrganism anchorEl={refUserMenuButton} open={openUserMenu} setOpen={setOpenUserMenu} />
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Container>
				</Toolbar>
			</AppBar>
		</HideOnScroll>
	)
}

export default AppBarOrganism
