'use client'

import { Grid2 as Grid, Typography } from '@mui/material'
import { Copyright as CopyrightIcon } from '@mui/icons-material'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const CopyRightAtom = () => {
	// Variables
	const { t } = useTranslation()

	return (
		<Grid size={12}>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				py={{ xs: 1.125, md: 2.25 }}
				sx={{
					color: '#c7c7c9',
					borderTop: '1px solid #ebebeb'
				}}
			>
				<CopyrightIcon sx={{ fontSize: 20, mr: 0.5 }} />

				<Typography component="span" lineHeight={2}>
					{t('common:description.copyright', { year: new Date().getFullYear() })}
				</Typography>
			</Grid>
		</Grid>
	)
}

export default CopyRightAtom
