import(/* webpackMode: "eager" */ "/home/tlicho-distance/components/atoms/copyright.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlicho-distance/components/molecules/boxes/ribbon.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlicho-distance/components/organisms/app-bar/desktop.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlicho-distance/components/organisms/app-bar/mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tlicho-distance/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tlicho-distance/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager" */ "/home/tlicho-distance/node_modules/@mui/material/Grid2/Grid2.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tlicho-distance/node_modules/@mui/material/MenuItem/MenuItem.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tlicho-distance/node_modules/@mui/material/MenuList/MenuList.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tlicho-distance/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/tlicho-distance/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/home/tlicho-distance/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/tlicho-distance/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
