'use client'

import dynamic from 'next/dynamic'
import { Container, Grid2 as Grid } from '@mui/material'
import { Spinorama, SpinoramaWrapper, SpinoramaItem, SpinoramaNext, SpinoramaPrev } from 'spinorama'
import { ArrowBackIosNewRounded as ArrowBackIosNewRoundedIcon, ArrowForwardIosRounded as ArrowForwardIosRoundedIcon } from '@mui/icons-material'

// Types
import type { RibbonMoleculepProps } from '@/types/components/molecules/boxes/ribbon'

// Components
const ImageAtom = dynamic(() => import('@/components/atoms/image'))

const RibbonMolecule = (props: RibbonMoleculepProps) => {
	const { items } = props

	return (
		<Grid size={12} bgcolor="#f1f9fe" height="200px">
			<Container maxWidth="lg" sx={{ height: '100%' }}>
				<Spinorama ease="power4.inOut" mt={1.5} pb={{ xs: 6, md: 'inherit' }} sx={{ height: '100%' }}>
					<SpinoramaWrapper sx={{ height: '100%' }}>
						{items.map((item, index) => (
							<SpinoramaItem key={index} size={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
								<ImageAtom {...item} />
							</SpinoramaItem>
						))}
					</SpinoramaWrapper>

					<SpinoramaPrev layout={'center-left'} showOnHover>
						<ArrowBackIosNewRoundedIcon />
					</SpinoramaPrev>

					<SpinoramaNext layout={'center-right'} showOnHover>
						<ArrowForwardIosRoundedIcon />
					</SpinoramaNext>
				</Spinorama>
			</Container>
		</Grid>
	)
}

export default RibbonMolecule
