'use client'

import { useState, useRef } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import { AppBar, Toolbar, Grid2 as Grid, Container, IconButton, Button, Avatar } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'

// Types
import type { AppBarProps } from '@/types/components/organisms/app-bar'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'
import { useUserStore } from '@/helpers/store/user'

// Components
const DrawerOrganism = dynamic(() => import('@/components/organisms/drawer'))
const LogoAtom = dynamic(() => import('@/components/atoms/logo'))
const GeneralButtonAtom = dynamic(() => import('@/components/atoms/buttons/general'))
const UserMobileMenuOrganism = dynamic(() => import('@/components/organisms/menu/user-mobile'))

const AppBarOrganism = (props: AppBarProps) => {
	// Props
	const { menus, avatarSRC } = props

	// Variables
	const { isLogin, username } = useUserStore()
	const { t } = useTranslation()
	const pathname = usePathname()
	const [open, setOpen] = useState<boolean>(false)
	const refUserMenuButton = useRef<HTMLButtonElement>(null)
	const [openUserMenu, setOpenUserMenu] = useState<boolean>(false)

	return (
		<AppBar
			elevation={0}
			sx={{
				display: { xs: 'block', md: 'none' },
				bgcolor: pathname === '/' ? 'transparent' : 'background.paper',
				'& .MuiToolbar-root': { p: 0 }
			}}
		>
			<Toolbar>
				<Container maxWidth="lg">
					<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ width: 1 }}>
						<Grid
							height={40}
							sx={{
								'& img': {
									width: 'auto',
									height: 40
								}
							}}
						>
							<LogoAtom />
						</Grid>

						<Grid>
							<Grid container spacing={0.5} direction="row" justifyContent="flex-start" alignItems="center">
								{!isLogin && (
									<>
										<Grid>
											<Link href="/signin">
												<GeneralButtonAtom title={t('form:button.signIn')} />
											</Link>
										</Grid>

										<Grid>
											<Link href="/register">
												<GeneralButtonAtom title={t('form:button.register')} />
											</Link>
										</Grid>
									</>
								)}

								{isLogin && (
									<Grid>
										<Button ref={refUserMenuButton} onClick={() => setOpenUserMenu(!openUserMenu)} sx={{ p: 0, minWidth: '40px', borderRadius: '50%' }}>
											<Avatar alt={username} src={avatarSRC} sx={{ bgcolor: 'primary.light' }}>
												{username ? username.charAt(0).toUpperCase() : null}
											</Avatar>
										</Button>

										<UserMobileMenuOrganism anchorEl={refUserMenuButton} open={openUserMenu} setOpen={setOpenUserMenu} />
									</Grid>
								)}

								<Grid>
									<IconButton aria-label="toggle menu visibility" onClick={() => setOpen(!open)}>
										<MenuIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>

				<DrawerOrganism open={open} setOpen={setOpen} items={menus} />
			</Toolbar>
		</AppBar>
	)
}

export default AppBarOrganism
